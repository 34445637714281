import { useLeaderboardGlobalFilterStore } from '#leaderboard/store/LeaderboardGlobalFilterStore'
import {
  EngagementOptionEnum,
  type EngagementOptionEnumType,
  ExchangeTierEnum,
  type ExchangeTierEnumType,
  FdvCategoryEnum,
  type FdvCategoryEnumType,
} from '@forgd/supabase'

interface ComparableValueFilterOption {
  from: number
  to: number
}

interface GlobalFilter {
  [key: string]: {
    value: boolean
    id: EngagementOptionEnumType | FdvCategoryEnumType | ExchangeTierEnumType | 'avgProjectFDV'
    comparableValue: number | EngagementOptionEnumType | ComparableValueFilterOption
    sectionId: 'businessModel' | 'marketCap' | 'exchangesTier'
    columnId: string
  }
}

interface TableItemCombined {
  marketMakerId: string
  marketMakerName: string
  imageUrl: string
  weightedRank: number
  depthRank: number
  volumeRank: number
  spreadRank: number
  projectsTracked: number
  avgProjectFDV: number
  tier1: number
  tier2: number
  tier3: number
  avgDollarDepth: number
  avgDepthOfTotal: number
  avgDailyVolume: number
  avgVolumeOfTotal: number
  avgSpreadPercent: number
  engagementOptions: EngagementOptionEnumType[]
  [key: string]: any
}

const filters: Ref<GlobalFilter> = ref({
  loanCallOption: {
    value: true,
    id: EngagementOptionEnum.LoanCallOption,
    comparableValue: EngagementOptionEnum.LoanCallOption,
    sectionId: 'businessModel',
    columnId: 'engagementOptions',
  },
  retainerWorkingCapital: {
    value: true,
    id: EngagementOptionEnum.RetainerWorkingCapital,
    comparableValue: EngagementOptionEnum.RetainerWorkingCapital,
    sectionId: 'businessModel',
    columnId: 'engagementOptions',
  },
  market_cap_xl: {
    value: true,
    comparableValue: {
      from: 1000000000,
      to: Number.POSITIVE_INFINITY,
    },
    sectionId: 'marketCap',
    columnId: 'avgProjectFDV',
    id: FdvCategoryEnum.Major,
  },
  market_cap_lg: {
    value: true,
    comparableValue: {
      from: 250000000,
      to: 1000000000,
    },
    sectionId: 'marketCap',
    columnId: 'avgProjectFDV',
    id: FdvCategoryEnum.Large,
  },
  market_cap_md: {
    value: true,
    comparableValue: {
      from: 50000000,
      to: 250000000,
    },
    sectionId: 'marketCap',
    columnId: 'avgProjectFDV',
    id: FdvCategoryEnum.Mid,
  },
  market_cap_sm: {
    value: true,
    comparableValue: {
      from: 1000000,
      to: 50000000,
    },
    sectionId: 'marketCap',
    columnId: 'avgProjectFDV',
    id: FdvCategoryEnum.Low,
  },
  market_cap_xs: {
    value: true,
    comparableValue: {
      from: 0,
      to: 1000000,
    },
    sectionId: 'marketCap',
    columnId: 'avgProjectFDV',
    id: FdvCategoryEnum.Micro,
  },
  exchangesTier1: {
    value: true,
    comparableValue: 1,
    sectionId: 'exchangesTier',
    columnId: 'tier1',
    id: ExchangeTierEnum.Tier1,
  },
  exchangesTier2: {
    value: true,
    comparableValue: 10,
    sectionId: 'exchangesTier',
    columnId: 'tier2',
    id: ExchangeTierEnum.Tier2,
  },
  exchangesTier3: {
    value: true,
    comparableValue: 100,
    sectionId: 'exchangesTier',
    columnId: 'tier3',
    id: ExchangeTierEnum.Tier3,
  },
})

export function useLeaderboardGlobalFilter() {
  const { $state } = useLeaderboardGlobalFilterStore()
  const applyFiltersGlobally = ref($state.applyFiltersGlobally)
  const toast = useAppToast()
  const baseToast = useToast()
  /**
   * We are unable to handle the UCheckbox selection state directly,
   * but we need to keep it checked when it's the last checkbox in the section.
   * The filterRefreshedAt will trigger a re-render of the parent component to restore the state.
   */
  const filterRefreshedAt = ref(Date.now())

  return {
    filters,
    applyFiltersGlobally,
    filterRefreshedAt,
    updateFilter: (filterId: string, newValue: boolean) => {
      baseToast.clear()

      const sectionId = filters.value[filterId].sectionId

      const selectedSectionFiltersQty = Object.keys(filters.value).filter(field => filters.value[field].sectionId === sectionId && filters.value[field].value).length

      if (selectedSectionFiltersQty <= 1 && !newValue) {
        toast.error({
          title: 'Review the filter configuration',
          description: 'Please ensure that in this category at least one option is selected.',
        })
      }
      else {
        filters.value[filterId].value = newValue
      }
      filterRefreshedAt.value = Date.now()
    },
    resetFilters: () => {
      baseToast.clear()
      for (const key in filters.value) {
        filters.value[key].value = true
      }
      filterRefreshedAt.value = Date.now()
    },
    filterData: (data: TableItemCombined[]): TableItemCombined[] => {
      if (!data || !data.length) {
        return [] as TableItemCombined[]
      }
      // check what checkboxes are set to true
      const activeFilters = Object.keys(filters.value).filter(key => !filters.value[key].value)
      if (activeFilters.length === 0) {
        return data
      }
      // data and active filters are set, time to double loop
      return data.filter((item) => {
        // Check if any active filter matches the columnId value in the item
        return activeFilters.every((filterName) => {
          const currentFilter = filters.value[filterName]
          if (!currentFilter) {
            return true
          }
          if (currentFilter.sectionId === 'businessModel') {
            // this is the data that we have if it matches the filter state
            const columnFilterValue = item[currentFilter.columnId]
            // if value mm item is null, we assume that this item is not going to be filtered
            if (!columnFilterValue || columnFilterValue.length < 1) {
              return true
            }
            return !columnFilterValue.includes(currentFilter.comparableValue)
          }
          else if (currentFilter.sectionId === 'marketCap') {
            const columnFilterValue = item[currentFilter.columnId]
            const comparableValue = currentFilter.comparableValue as ComparableValueFilterOption
            return columnFilterValue < comparableValue.from || columnFilterValue >= comparableValue.to
          }
          else if (currentFilter.sectionId === 'exchangesTier') {
            return item[currentFilter.columnId] !== undefined && item[currentFilter.columnId] < currentFilter.comparableValue
          }
          return true
        })
      })
    },
  }
}
