<script setup lang="ts">
import type { StatProps } from './UiStat.vue'
import { twMerge } from 'tailwind-merge'

const props = withDefaults(defineProps<{
  data: StatProps[]
  variant?: 'card' | 'cards' | 'inline'
  wrap?: boolean
}>(), {
  variant: 'card',
  wrap: false,
})

const classes = useAttrs().class

const containerClass = computed(() => {
  return twMerge(
    'group flex flex-row justify-around gap-5',
    props.wrap && 'flex-wrap',
    props.variant === 'card' && 'px-5 py-4 sm:px-5 sm:py-4 rounded-lg border border-neutral-600 bg-white',
    classes,
  )
})

const statClass = computed(() => {
  return 'shrink-0 grow'
})
</script>

<template>
  <div data-ui="UiStats" :class="containerClass">
    <template v-for="(item, index) in data" :key="item.title">
      <slot v-bind="{ item, index }">
        <UiStat
          v-bind="item"
          :variant="variant === 'cards' ? 'card' : 'inline'"
          :align="item.align ?? 'center'"
          :class="statClass"
        />
      </slot>
    </template>
  </div>
</template>
