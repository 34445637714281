<script lang="ts" setup>
import QuotePreferences from '../components/QuotePreferences.vue'

useHead({
  title: 'RFQ Details',
})
const rfqDetails = useLiquidityRFQDetails()
const { navItems, summaryNavItem, status, details, backButtonUrl } = storeToRefs(rfqDetails)

onBeforeUnmount(() => {
  rfqDetails.reset()
})

watch(status, (value) => {
  if (value === 'error') {
    navigateTo('/page-not-found')
  }
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout name="dashboard">
    <template #header>
      <div class="flex gap-2 items-center">
        <UiButton :to="backButtonUrl" size="sm" icon="i-heroicons-arrow-left" class="h-8">
          Back
        </UiButton>
        <ProjectImage v-if="details" :src="details?.project.image" class="w-6 h-6" />
        <span v-if="details" class="font-semibold">
          {{ details?.project.name }} RFQ Details
        </span>
      </div>
    </template>
    <template #sidebar>
      <div
        class="flex flex-col justify-start h-full gap-2 border-r border-forgd-bgd-600 w-[14.25rem] px-2 py-4"
      >
        <nav aria-label="RFQ Details Navigation" class="flex flex-col flex-shrink-0 sticky top-0 gap-1">
          <UButton
            :key="summaryNavItem.to"
            :to="summaryNavItem.to"
            color="gray"
            variant="ghost"
            active-class="bg-neutral-400"
            class="w-full h-8.5 rounded hover:bg-neutral-400 p-2"
          >
            <UIcon :name="summaryNavItem.icon" class="w-3.5 h-3.5" :class="summaryNavItem.iconColor" />
            <span class="text-xs whitespace-nowrap text-gray-600 font-normal">
              {{ summaryNavItem.label }}
            </span>
          </UButton>
          <div class="border-b border-forgd-bgd-600 my-4" />
          <span class="w-full text-xs font-normal text-neutral-700 mb-1">Process Steps</span>
          <UButton
            v-for="navItem in navItems"
            :key="navItem.to"
            :to="navItem.to"
            color="gray"
            variant="ghost"
            active-class="bg-neutral-400"
            class="w-full h-8.5 rounded hover:bg-neutral-400 p-2"
          >
            <UIcon :key="navItem.icon" :name="navItem.icon" class="w-3.5 h-3.5" :class="navItem.iconColor" />
            <span class="text-xs whitespace-nowrap text-gray-600 font-normal">
              {{ navItem.label }}
            </span>
          </UButton>
        </nav>
      </div>
    </template>
    <QuotePreferences v-if="details" :data="details" :loading="!details?.rfq" />
    <slot />
  </NuxtLayout>
</template>
