import { withTrailingSlash } from 'ufo'

export const showSupportModal = ref(false)
export const topLevelMenu = computed(() => {
  return [
    [
      {
        icon: 'i-heroicons-home',
        to: '/active-rfqs',
        label: 'Home & RFQs',
        on: closeSlideoverOnMouseover,
        shouldHighlight: (currentPath: string) => {
          return [
            'active-rfqs',
            'closed-rfqs',
            'summary',
            'round-1-my-quotes',
            'round-1-outcome',
            'round-2-my-quotes',
            'round-2-outcome',
            'my-engagements',
            'leaderboard-opt-ins',
          ].some((url: string) => currentPath.endsWith(url))
        },
      },
      {
        icon: 'i-heroicons-chart-pie',
        label: 'Monitor Active Projects',
        to: '/monitor-active-projects',
        isStrict: true,
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-trophy',
        label: 'Leaderboards',
        to: '/leaderboards',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-academic-cap',
        label: 'Forgd Academy',
        key: 'forgd-academy',
        on: {
          mouseenter(event) {
            handleSlideoverChange({
              event,
              slideover: 'forgd-academy',
            })
          },
        },
        shouldHighlight: (currentPath: string) => {
          return currentPath.startsWith('/academy')
        },
      },
    ],
    [
      {
        icon: 'i-heroicons-information-circle',
        to: 'https://www.forgd.com/about',
        label: 'About Forgd',
        external: true,
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
        label: 'Support',
        on: {
          click: () => {
            showSupportModal.value = true
          },
          ...closeSlideoverOnMouseover,
        },
      },
      {
        icon: 'i-heroicons-arrow-right-start-on-rectangle',
        label: 'Logout',
        loading: isLoggingOut.value,
        on: {
          click: logout,
          ...closeSlideoverOnMouseover,
        },
        testId: 'logout',
      },
    ],
  ] satisfies NavLink[][]
})

export function isCurrent({ to, shouldHighlight }: NavLink, strict: boolean = false) {
  const route = useRoute()
  // we just care about the first path segment matching
  const pathSegments = (to || '').split('/').filter(Boolean)
  const currPathSegments = (route.path || '').split('/').filter(Boolean)
  if (!strict) {
    if (withTrailingSlash(currPathSegments[0]) === withTrailingSlash(pathSegments[0])) {
      return true
    }

    if (shouldHighlight && shouldHighlight(route.path)) {
      return true
    }
  }
  // special logic, we match the top-level category which is the first 3 segments
  if (route.path.startsWith('/academy')) {
    const categoryPath = currPathSegments.slice(0, 3).join('/')
    return withTrailingSlash(categoryPath) === withTrailingSlash(pathSegments.slice(0, 3).join('/'))
  }
  if (!to) {
    return false
  }
  // requires full match
  return withTrailingSlash(to) === withTrailingSlash(route.path)
}
