<script lang="ts" setup>
const { liquidityMe } = storeToRefs(useAuth())
</script>

<template>
  <UCard class="bg-transparent">
    <div class="flex items-center gap-1 h-8 px-2">
      <img
        :src="resolveLogoWithFallback('market-maker', liquidityMe?.marketMakerImage)"
        class="h-4 w-4 rounded-full border border-neutral-600"
        :alt="`${liquidityMe?.marketMakerName} Logo`"
      >
      <div class="text-xs font-semibold">
        {{ liquidityMe?.marketMakerName }}
      </div>
    </div>
  </UCard>
</template>
