<template>
  <NuxtLoadingIndicator />
  <NuxtLayout name="dashboard">
    <template #header>
      <div class="flex gap-2 items-center">
        <UiButton to="/my-engagements" size="sm" icon="i-heroicons-arrow-left" class="h-8">
          Back
        </UiButton>
        <span class="font-semibold">
          Engagement Details
        </span>
      </div>
    </template>
    <slot />
  </NuxtLayout>
</template>
