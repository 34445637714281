import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLeaderboardGlobalFilterStore = defineStore('leaderboard-global-filter', () => {
  const applyFiltersGlobally = ref(true)

  function setLeaderboardGlobalFilter(value: boolean) {
    applyFiltersGlobally.value = value
  }

  return {
    applyFiltersGlobally,
    setLeaderboardGlobalFilter,
  }
})
